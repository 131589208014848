import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ShortenerForm from '../components/shortenerForm'
import Lightbox from '../components/lightbox'
import SubFooter from '../components/subFooter'
import Footer from '../components/footer'
import Link from '../components/anchor'

const VIDEOS = [
  {
    src: 'https://www.youtube.com/embed/LesnixX76YY?autoplay=1',
    thumbnail: 'https://i.ytimg.com/vi/LesnixX76YY/hqdefault.jpg'
  }
]

class FreeUrlShortener extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      variant: 1
    }
  }

  componentDidMount () {
    window.dataLayer = window.dataLayer || []
    function gtag () { window.dataLayer.push(arguments) }
    gtag('js', new Date())

    gtag('config', 'UA-68215349-1', { 'optimize_id': 'GTM-W5PQBDJ' })
    const that = this
    function implementManyExperiments (value) {
      that.setState({
        variant: value
      })
    }
    gtag('event', 'optimize.callback', {
      callback: implementManyExperiments
    })
  }

  render () {
    const { isOpen } = this.state
    return (
      <Layout
        type='empty'
        noMeta
      >
        <Helmet bodyAttributes={{
          class: 'body-no-padding'
        }}
        >
          <title>Free URL Shortener</title>
          <meta
            content='The perfect free URL shortener for transforming long, ugly links into nice, memorable and trackable short URLs. Use it to shorten links'
            name='description'
          />
          <meta
            content='Free URL Shortener'
            property='og:title'
          />
          <meta
            content='RB.GY - Create short and memorable links for free'
            property='og:description'
          />
          <meta property='og:image' content='https://free-url-shortener.rb.gy/open-graph.png' />
          <meta property='og:url' content='https://free-url-shortener.rb.gy' />
          <meta property='og:type' content='website' />
          <meta content='summary' name='twitter:card' />
          <meta content='width=device-width, initial-scale=1' name='viewport' />
          <link href='images/rebrandly-webclip.png' rel='apple-touch-icon' />

          <style>
            {`.freetooltip {
              position: relative;
          }
          
          .freetooltip .tooltiptext {
              visibility: hidden;
              /* min-width: 260px; */
              background-color: #fff;
              border: 1px solid #E8E9EA;
              box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
              font-size: 15px;
              color: #212732;
              text-align: left;
              border-radius: 6px;
              padding: 6px 12px;
              /* Position the tooltip */
              position: absolute;
              z-index: 1;
              bottom: 100%;
              left: 20%;
              margin-left: -130px;
              margin-bottom: -1px;
          }
          
          @media only screen and (max-width: 1450px) {
              .freetooltip .tooltiptext {
                  visibility: hidden;
                  /* min-width: 260px; */
                  background-color: #fff;
                  border: 1px solid #E8E9EA;
                  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
                  font-size: 15px;
                  color: #212732;
                  text-align: left;
                  border-radius: 6px;
                  padding: 6px 12px;
                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  bottom: 100%;
                  left: 100%;
                  margin-left: -100%;
              }
          }
          
          @media only screen and (max-width: 991px) {
              .freetooltip .tooltiptext {
                  visibility: hidden;
                  min-width: 260px;
                  background-color: #fff;
                  border: 1px solid #E8E9EA;
                  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
                  font-size: 15px;
                  color: #212732;
                  text-align: left;
                  border-radius: 6px;
                  padding: 6px 12px;
                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  bottom: 100%;
                  left: 50%;
                  margin-left: -130px;
                  margin-bottom: 4px;
              }
          }
          
          .freetooltip:hover .tooltiptext {
              visibility: visible;
          }`}
          </style>
        </Helmet>
        <ShortenerForm
          variant={this.state.variant}
          className='section free-url-shortener'
          title={<h1 className='white-text'>Free SMART TV URL Shortener</h1>}
          description={
            <div className='terms-copy terms-copy-variant w-hidden-small'>RB.GY is a free tool to shorten URLs.
              Create short & memorable links in seconds.
              <br />
              <br />
            </div>
          }
          placeholder='Enter link here'
          sitekey='6LfdFbgUAAAAAPela23tB6kuh06cslnUked_PTWo'
          cookie='links-data-external'
          limitLinksDisplay={5}
        />
        {/*
       <div className='section zebra'>
          <div className='container'>
            <div className='container-small-inside-center-720'>
              <h2 className='new_h1_dark no-margin'>
                <strong className='new_h2_dark'>A fast and simple URL shortener</strong>
              </h2>
              <br />
              <p className='paragraph_dark_center'>
                RB.GY is the perfect free URL shortener for transforming long, ugly
                links into nice, memorable and trackable short URLs. Use it to shorten
                links for any social media platforms, blogs, SMS, emails, ads, or pretty
                much anywhere else you want to share them. Twitter, Facebook, YouTube, Instagram, WhatsApp, emails, SMS, videos. RB.GY is <b>the best free alternative to generic URL shorteners like bitly and tinyurl</b>.
                After shorterning the URL, <a href='/stats/index.html'>check how many clicks it received</a>
              </p>
            </div>
          </div>
        </div>
        <div className='section'>
          <div className='container'>
            <h2 className='new_h1_dark no-margin'><strong className='new_h2_dark'>Short URLs or Branded Links?</strong></h2>
            <br />
            <div className='div-block-4'>
              <Link
                href='#' className='w-lightbox play-btn' onClick={(e) => {
                  e.preventDefault()
                  this.setState({ isOpen: true })
                }}
              >
                <img src='images/free-url-shortener.jpg' alt='Short URLs or Branded Links?' className='video_preview fullwidth' />
              </Link>
            </div>
          </div>
          <div className='container hero'>
            <div className='div_form_horizontal_form banner'>
              <div className='rebrand-link-block home'>
                <div className='rebrand-link-limit-reached-banner-container'>
                  <div className='rebrand-link-limit-reached-banner-copy'>
                    <img
                      className='rebrand-link-limit-reached-banner-ill w-hidden-small'
                      src='./images/ill-thunder.svg'
                      width={35}
                      height={63}
                      alt='domains'
                    />
                    <div className='titles'>
                      <h3 className='w-hidden-tiny w-hidden-small'>
                        Branded domains for just $2/year
                      </h3>
                      <h3 className='w-hidden-medium w-hidden-main'>
                        Branded domains
                        <br />
                        for just $2/year
                      </h3>
                      <h5 className='w-hidden-tiny w-hidden-small cta-description'>
                        Register a new custom domain and start creating branded links.
                        Increase click-through-rate up to 39%.
                      </h5>
                      <h5 className='w-hidden-medium w-hidden-main cta-description'>
                        Register a new custom domain and start creating branded links.<br />
                        Increase click-through-rate up to 39%.
                      </h5>
                    </div>
                  </div>
                  <div className='rebrand-link-limit-reached-banner-cta'>
                    <div className='flex-column flex-row-mobile center-xs'>
                      <Link
                        href='https://httpslink.com/2dollars'
                        target='_blank'
                        className='btn-secondary block-xs'
                      >
                        Search domains
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      */}
        <Footer />
        <SubFooter />
        <Lightbox
          open={isOpen}
          onCloseRequest={() => this.setState({ isOpen: false })}
          mainSrc={0}
          elements={VIDEOS}
        />
      </Layout>
    )
  }
}

export default FreeUrlShortener
