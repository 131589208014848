module.exports = [
  'goo.gl',
  'adf.ly',
  'bc.vc',
  'tinyurl.com',
  'gestyy.com',
  'smarturl.it',
  'zo.ee',
  'cl.ly',
  'bit.ly',
  'po.st',
  'cutt.us',
  'd.pr',
  'bit.do',
  'app.link',
  'ow.ly',
  'grabify.link',
  'cur.lv',
  'v.gd',
  'al.ly',
  'is.gd',
  'tr.im',
  'filoops.info',
  'q.gs',
  'gg.gg',
  'pen.io',
  'u.to',
  'x.co',
  'ity.im',
  'yourls.org',
  'tinyarrows.com',
  'qr.net',
  'dft.ba',
  'bitly.im',
  '1url.com',
  '366.io',
  'adcraft.co',
  'adcrun.ch',
  'adflav.com',
  'aka.gr',
  'azz.io',
  'bee4.biz',
  'bmeo.org',
  'buzurl.com',
  'cektkp.com',
  'click.gl',
  'cor.to',
  'db.tt',
  'fun.ly',
  'fzy.co',
  'gog.li',
  'golinks.co',
  'gph.to',
  'hit.my',
  'id.tl',
  'j.mp',
  'link.zip.net',
  'linkto.im',
  'lnk.co',
  'lnkd.in',
  'minilink.es',
  'nov.io',
  'p6l.org',
  'picz.us',
  'prettylinkpro.com',
  'qr.ae',
  're.tc',
  'scrnch.me',
  'shortquik.com',
  'shorturl.at',
  'short.cm',
  'short.io',
  'sk.gy',
  'su.pr',
  'thndr.me',
  'tota2.com',
  'tweez.me',
  'u.bb',
  'vzturl.com',
  'xlink.co',
  'xlinkz.info',
  'xtu.me',
  'yu2.it',
  'zpag.es',
  'shrtn.pro',
  'rb.gy',
  'rebrand.ly'
]
