import React, { Component } from 'react'
import { node, string, number } from 'prop-types'
import Link from './anchor'
import { setCookie, getCookie } from '../../static/js/mixins'
import { isURL } from 'validator'
import URL from 'url-parse'
import blacklist from '../utils/blacklist'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'

const LIMIT_LINKS_DISPLAY = 5
const TEST_HOSTNAMES = ['127.0.0.1', 'localhost', 'free-url-shortener.test.rb.gy']
const NOTIFY_TIMEOUT = 2500

class ShortenedLink extends Component {
  state = {
    notify: {}
  }
  copyToClipboard (referral, e) {
    e.preventDefault()
    referral.select()
    referral.setSelectionRange(0, 99999)
    document.execCommand('copy')
    this.setState({ notify: { message: 'Link copied', type: 'info' } })
    this.timeout && clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setState({ notify: {} }), NOTIFY_TIMEOUT)
  }

  renderCopyNotification () {
    if (this.state.notify.type === 'info') {
      return (
        <div className='shortened-links-notify-container shortened-links-notify-container-copy'>
          <div className='shortened-links-notify success'>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path fillRule='evenodd' clipRule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='#02AF63' />
              <path d='M6.61899 9.79217L4.82228 8.09006L4.21045 8.66559L6.61899 10.9473L11.7894 6.04917L11.1819 5.47363L6.61899 9.79217Z' fill='white' />
            </svg>
            <span>{this.state.notify.message}</span>
          </div>
        </div>
      )
    } else return null
  }

  render () {
    let referral
    const { destination, shortUrl } = this.props
    return (
      <div className='shortened-links-list-item shortened-links-list-item-copy'>
        {this.renderCopyNotification()}
        <div className='origin-link'>
          {destination.split('//')[1]}
        </div>
        <div className='short-link'>
          <input
            ref={ref => (referral = ref)}
            className='short-link-hidden'
            value={shortUrl}
          />
          <a href={shortUrl} title={shortUrl} target='_blank'>
            {shortUrl.split('//')[1]}
          </a>
        </div>
        <div className='copy-link'>
          <a href='#' onClick={(e) => this.copyToClipboard(referral, e)}>
            <span className='w-hidden-medium w-hidden-small w-hidden-tiny'>
              Copy
            </span>
            <span className='w-hidden-main'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 48 48'
                className='injected-svg Icon--sm v-middle'
                data-src='https://dashboard-cdn.rebrandly.com/styleguide-assets/icons/ic-copy.svg'
                fill='#909398'
              >
                <g>
                  <g>
                    <path d='M28 8H16c-2.2 0-4 1.8-4 4v20h2c0-11.3 0-17.64 0-19 0-1 2-3 3-3 1.33 0 5 0 11 0V8zm4.8 4H19.2c-1.76 0-3.2 1.6-3.2 3.56v20.88C16 38.4 17.44 40 19.2 40h13.6c1.76 0 3.2-1.6 3.2-3.56V15.56C36 13.6 34.56 12 32.8 12z' />
                  </g>
                </g>
              </svg>
            </span>
          </a>
          <a className='stats-link' target='_blank' href={`${shortUrl}+`}>
            <span className='w-hidden-medium w-hidden-small w-hidden-tiny'>
              Stats
            </span>
            <span className='w-hidden-main'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 48 48'
                className='injected-svg Icon--tiny v-middle'
                data-src='https://dashboard-cdn.rebrandly.com/styleguide-assets/icons/ic-stats.svg'
                fill='#909398'
              ><g><g><path d='M23 40h2c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v30c0 .55.45 1 1 1zm-10 0h2c.55 0 1-.45 1-1V25c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1zm19-23v22c0 .55.45 1 1 1h2c.55 0 1-.45 1-1V17c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1z' /></g></g>
              </svg>
            </span>
          </a>
        </div>
      </div>
    )
  }
}

ShortenedLink.propTypes = {
  shortUrl: string,
  destination: string
}

class ShortenerForm extends Component {
  constructor (props) {
    super()
    this.state = {
      mergedLinks: JSON.parse(getCookie(props.cookie || 'links-data')) || {
        entries: []
      },
      destination: '',
      isLoading: false,
      links: [],
      notify: {}
    }
    this.endPoint = this.isTestEnv() ? 'https://free-url-shortener.test.rb.gy/shorten' : 'https://free-url-shortener.rb.gy/shorten'
    this.recaptchaRef = React.createRef()
  }

  validate (e) {
    e.preventDefault()
    const { hostname } = new URL(this.state.destination.startsWith('http') ? this.state.destination : `http://${this.state.destination}`, {}, true)
    if (!isURL(this.state.destination, { allow_underscores: true }) || blacklist.some((_host) => _host === hostname)) {
      this.setState({ notify: { message: 'Invalid URL', type: 'error' } })
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(() => this.setState({ notify: {} }), NOTIFY_TIMEOUT)
      return false
    }
    if (this.state.mergedLinks.entries.some(entry => entry.destination === this.state.destination || entry.destination === `http://${this.state.destination}`)) {
      this.setState({ notify: { message: 'Already shortened', type: 'error' } })
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(() => this.setState({ notify: {} }), NOTIFY_TIMEOUT)
      return false
    }
    this.recaptchaRef.current.execute()
  }

  isTestEnv () {
    if (typeof location === 'undefined') return null
    return (
      TEST_HOSTNAMES.some((host) => host === location.hostname)
    )
  }

  renderShortenedLinks () {
    return this.state.mergedLinks.entries.slice(0, this.props.limitLinksDisplay || LIMIT_LINKS_DISPLAY).map(entry => this.renderShortenedLink(entry))
  }

  renderShortenedLink ({ destination, shortUrl }) {
    return (
      <ShortenedLink destination={destination} shortUrl={shortUrl} />
    )
  }

  // renderPremiumBanner () {
  //   return (
  //     <div
  //       className='rebrand-link-limit-reached-banner-container'
  //     >
  //       <div className='rebrand-link-limit-reached-banner-copy'>
  //         <img
  //           className='rebrand-link-limit-reached-banner-ill w-hidden-small'
  //           src='./images/ill-thunder.svg'
  //           width={35}
  //           height={63}
  //           alt='domains'
  //         />
  //         <div className='titles'>
  //           <h3 className='w-hidden-tiny w-hidden-small'>
  //             Branded domains for just $2/year
  //           </h3>
  //           <h3 className='w-hidden-medium w-hidden-main'>
  //             Branded domains
  //             <br />
  //             for just $2/year
  //           </h3>
  //           <h5 className='w-hidden-tiny w-hidden-small cta-description'>
  //             Register a new custom domain and start creating branded links.
  //             Increase click-through-rate up to 39%.
  //           </h5>
  //           <h5 className='w-hidden-medium w-hidden-main cta-description'>
  //             Register a new custom domain and start creating branded links.
  //             Increase click-through-rate up to 39%.
  //           </h5>
  //         </div>
  //       </div>
  //       <div className='rebrand-link-limit-reached-banner-cta'>
  //         <div className='flex-column flex-row-mobile center-xs'>
  //           <Link
  //             href='https://httpslink.com/2dollars'
  //             target='_blank'
  //             className='btn-secondary block-xs'
  //           >
  //             Search domains
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  renderLimitBanner () {
    return (
      <div
        className='rebrand-link-limit-reached-banner-container'
      >
        <div className='rebrand-link-limit-reached-banner-copy'>
          <img
            className='rebrand-link-limit-reached-banner-ill w-hidden-small'
            src='./images/ill-upgrade.svg'
            width={72}
            height={72}
            alt='upgrade'
          />
          <div className='titles'>
            <h3 className='w-hidden-tiny w-hidden-small'>
              Want more? Try Premium Features!
            </h3>
            <h3 className='w-hidden-medium w-hidden-main'>
              Want more?
              <br />
              Try Premium Features!
            </h3>
            <h5 className='w-hidden-tiny w-hidden-small cta-description'>
              Branded short URLs, detailed analytics, UTM builder,
              QR codes, powerful dashboard and much more. Only $9/month.
            </h5>
            <h5 className='w-hidden-medium w-hidden-main cta-description'>
              Branded short URLs, detailed analytics, UTM builder,
              QR codes, powerful dashboard and much more. Only $9/month.
            </h5>
          </div>
        </div>
        <div className='rebrand-link-limit-reached-banner-cta'>
          <div className='flex-column flex-row-mobile center-xs'>
            <Link
              href='https://httpslink.com/srt8'
              target='_blank'
              className='btn-secondary block-xs'
            >
              Go Premium
            </Link>
            <div>
              <span className='font-small'>&nbsp;or&nbsp;</span>
              <Link
                href='https://httpslink.com/qpin'
                target='_blank'
                className='signup-btn-cta m-t-12'
              >
                sign up free
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNotification () {
    if (this.state.notify.type) {
      if (this.state.notify.type === 'error') {
        return (
          <div className='shortened-links-notify-container'>
            <div className='shortened-links-notify error'>
              <svg
                width={16}
                height={16}
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M1.65844 13.9181C1.8066 14.0037 1.97466 14.0487 2.14573 14.0487H13.8548C14.3931 14.0487 14.8294 13.6121 14.8294 13.0735C14.8294 12.9024 14.7844 12.7342 14.6989 12.586L8.84436 2.43898C8.57524 1.97253 7.97915 1.81269 7.51296 2.08196C7.36475 2.16756 7.24169 2.29069 7.15613 2.43898L1.30162 12.586C1.03249 13.0524 1.19225 13.6488 1.65844 13.9181ZM8.58553 11.317C8.58553 11.6403 8.32346 11.9024 8.00017 11.9024C7.67688 11.9024 7.4148 11.6403 7.4148 11.317C7.4148 10.9937 7.67688 10.7317 8.00017 10.7317C8.32346 10.7317 8.58553 10.9937 8.58553 11.317ZM8.00017 9.95117C8.21569 9.95117 8.39041 9.77645 8.39041 9.56093V6.43898C8.39041 6.22345 8.21569 6.04873 8.00017 6.04873C7.78464 6.04873 7.60992 6.22345 7.60992 6.43898V9.56093C7.60992 9.77645 7.78464 9.95117 8.00017 9.95117Z'
                  fill='#D34547'
                />
              </svg>
              <span>{this.state.notify.message}</span>
            </div>
          </div>
        )
      } else if (this.state.notify.type === 'info') {
        return (
          <div className='shortened-links-notify-container'>
            <div className='shortened-links-notify success'>
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
                <path fillRule='evenodd' clipRule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='#02AF63' />
                <path d='M6.61899 9.79217L4.82228 8.09006L4.21045 8.66559L6.61899 10.9473L11.7894 6.04917L11.1819 5.47363L6.61899 9.79217Z' fill='white' />
              </svg>
              <span>{this.state.notify.message}</span>
            </div>
          </div>
        )
      }
    }
  }

  onRecaptcha (value) {
    this.setState({ isLoading: true })
    const headers = {
      'g-recaptcha-response': value,
      'content-type': 'application/x-www-form-urlencoded'
    }
    const destination = (this.state.destination.startsWith('http://') || this.state.destination.startsWith('https://')) ? this.state.destination : 'http://' + this.state.destination
    if (value) {
      // this.setState({ countedLinks: this.state.countedLinks + 1 })
      const jsonData = {
        destination
      }
      const jsonDataDryRun = {
        destination,
        dryRun: true
      }
      const responses = [axios({
        method: 'post',
        url: this.endPoint,
        headers,
        data: jsonData
      }),
      axios({
        method: 'post',
        url: this.endPoint,
        headers,
        data: jsonDataDryRun
      })
      ]

      axios.all(responses).then(axios.spread(({ data }, dry) => {
        const shiftedLinks = { entries: [...this.state.mergedLinks.entries.slice(0, this.props.limitLinksDisplay - 1 || LIMIT_LINKS_DISPLAY - 1)] }
        shiftedLinks.entries.unshift({
          destination,
          shortUrl: data.shortUrl
        })
        setCookie(this.props.cookie || 'links-data', JSON.stringify(shiftedLinks), 1)
        this.setState({
          destination: '',
          isLoading: false,
          mergedLinks: shiftedLinks
        })
        this.recaptchaRef.current.reset()
      }))
    }
  }

   handleChange = (e) => {
     const { name, value } = e.target
     this.setState({
       [name]: value
     })
     if (e.target.nextSibling) { e.target.nextSibling.focus() }
   }

   handleCodeSubmit = (e) => {
     const { input0, input1, input2, input3 } = this.state
     e.preventDefault()
     window.open(`http://tv-test.rb.gy/${input0}${input1}${input2}${input3}`, '_blank')
   }

   render () {
     return (
       <div className={`form form-small-pad shorten-section ${this.props.className}`}>
         {this.props.title || null}
         <div className='container hero'>
           {/*
           <div className='div_form_horizontal_form'>
            <div className='rebrand-link-block home'>
              <div className='w-form'>
                {this.props.description || null}
                <form
                  onSubmit={(e) => this.validate(e)}
                  id='wf-form-rebrand-link-form'
                  name='wf-form-rebrand-link-form'
                  data-name='rebrand-link-form'
                  action='https://app.rebrandly.com/links/new'
                  className='form-link-creation home'
                >
                  {this.renderNotification()}
                  <label htmlFor='url' className='text-indent'>Destination</label>
                  <input
                    type='text'
                    className='form-link-creation-input w-input'
                    maxLength={256}
                    name='url'
                    data-name='Url'
                    placeholder={this.props.placeholder || 'Make your links shorter'}
                    id='url'
                    value={this.state.isLoading ? '' : this.state.destination}
                    onChange={e => this.setState({ destination: e.target.value })}
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    disabled={this.state.isLoading}
                  />
                  <input
                    type='submit'
                    value='Shorten URL'
                    disabled={this.state.isLoading}
                    className='btn-primary-3 w-button'
                  />
                  <div className='g-recaptcha'>
                    <ReCAPTCHA
                      size='invisible'
                      badge='bottomleft'
                      ref={this.recaptchaRef}
                      sitekey={this.props.sitekey || '6LfdFbgUAAAAAPela23tB6kuh06cslnUked_PTWo'}
                      onChange={(e) => this.onRecaptcha(e)}
                    />
                  </div>
                </form>
                <div className='shortened-links-list'>
                  {this.renderShortenedLinks()}
                </div>
                {this.renderLimitBanner()}
                <div className='terms-copy w-hidden-main'>
                  By clicking shorten url, you agree to <br />Rebrandly’s
                  {' '}<Link href='https://rebrandly.com/terms-conditions' target='_blank'>
                    Terms of Use
                  </Link>
                </div>
                <div className='terms-copy w-hidden-small'>
                  By clicking shorten url, you agree to Rebrandly’s{' '}
                  <Link href='https://rebrandly.com/terms-conditions' target='_blank'>
                    Terms of Use
                  </Link>
                </div>

              </div>
            </div>
          </div>
           */}
         </div>
         <div className='flex'>
           <form onSubmit={this.handleCodeSubmit}>
             <input
               type='text'
               className='form-link-creation-input form-link-creation-input--digit'
               maxLength={1}
               name='input0'
               data-name='input-0'
               placeholder='_'
               id='input-0'
               value={this.state.input0}
               onChange={this.handleChange}
               autoComplete='off'
               autoCorrect='off'
               autoCapitalize='none'
             />
             <input
               type='text'
               className='form-link-creation-input form-link-creation-input--digit'
               maxLength={1}
               name='input1'
               data-name='input1'
               placeholder='_'
               id='input-1'
               value={this.state.input1}
               onChange={this.handleChange}
               autoComplete='off'
               autoCorrect='off'
               autoCapitalize='none'
             />
             <input
               type='text'
               className='form-link-creation-input form-link-creation-input--digit'
               maxLength={1}
               name='input2'
               data-name='input-2'
               placeholder='_'
               id='input-2'
               value={this.state.input2}
               onChange={this.handleChange}
               autoComplete='off'
               autoCorrect='off'
               autoCapitalize='none'
             />
             <input
               type='text'
               className='form-link-creation-input form-link-creation-input--digit'
               maxLength={1}
               name='input3'
               data-name='input-3'
               placeholder='_'
               id='input-3'
               value={this.state.input3}
               onChange={this.handleChange}
               autoComplete='off'
               autoCorrect='off'
               autoCapitalize='none'
             />
             <input type='submit' value='GO' />
           </form>
         </div>
       </div>
     )
   }
}

ShortenerForm.propTypes = {
  title: node,
  placeholder: string,
  cookie: string,
  description: node,
  limitLinksDisplay: number,
  sitekey: string,
  variant: number,
  className: string
}

export default ShortenerForm
