import React, { Component } from 'react'
import Link from './anchor'

class Footer extends Component {
  constructor () {
    super()
    this.state = { showContactModal: false }
  }

  render () {
    return (
      <div className='footer'>
        <div className='container div_vertival_flow'>
          <a className='link-footer' href='https://rebrandly.com' target='_blank'>Powered by <img src='/images/url-shortener.svg' alt='Url shortener' className='logo-header' /></a>
          {!this.props.hideLinks && <div className='line-footer' />}
          {/* <h6 className='footer-heading-single-col'>
      LEARN BRANDED LINK MANAGEMENT WITH REBRANDLY
          </h6> */}
          {!this.props.hideLinks && <div className='footer-container'>
            <div className='footer-col extra-links-col'>
              <Link
                href='https://blog.rebrandly.com/what-is-a-branded-link'
                target='_blank'
                className='link-footer extra-link'
              >
                What is a branded link
              </Link>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/360007189533-utm-builder'
                target='_blank'
                className='link-footer extra-link'
              >
                UTM builder
              </Link>
              <Link
                href='https://blog.rebrandly.com/what-is-link-retargeting/'
                target='_blank'
                className='link-footer extra-link'
              >
                Link retargeting
              </Link>
              <Link
                href='https://rebrandly.com'
                target='_blank'
                className='link-footer extra-link'
              >
                Link shortener
              </Link>
            </div>
            <div className='footer-col extra-links-col'>
              <Link
                href='https://clickmeter.com'
                target='_blank'
                className='link-footer extra-link'
              >
                Link tracking
              </Link>
              <Link
                href='https://free-url-shortener.rb.gy/'
                className='link-footer extra-link'
              >
                Free URL shortener
              </Link>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/227131707-Bitly'
                target='_blank'
                className='link-footer extra-link'
              >
                Bitly URL shortener
              </Link>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/227132547-TinyURL'
                target='_blank'
                className='link-footer extra-link'
              >
                TinyURL alternative
              </Link>
            </div>
            <div className='footer-col extra-links-col'>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/115003864474-YouTube-URL-Shortener-'
                target='_blank'
                className='link-footer extra-link'
              >
                Youtube URL shortener
              </Link>
              <Link
                href='https://rebrandly.com'
                target='_blank'
                className='link-footer extra-link'
              >
                Custom short urls
              </Link>
              <Link
                href='https://blog.rebrandly.com/vanity-url/'
                target='_blank'
                className='link-footer extra-link'
              >
                Vanity URL
              </Link>
              <Link
                href='https://blog.rebrandly.com/150-best-marketing-tools'
                target='_blank'
                className='link-footer extra-link'
              >
                Marketing tools
              </Link>
            </div>
            <div className='footer-col extra-links-col'>
              <Link
                href='https://blog.rebrandly.com/the-6-best-url-shorteners-and-how-to-choose-the-right-one-for-you/'
                target='_blank'
                className='link-footer extra-link'
              >
                Best URL shorteners
              </Link>
              <Link href='https://rebrandly.com/sms' target='_blank' className='link-footer extra-link'>
                SMS marketing
              </Link>
              <Link
                href='https://blog.rebrandly.com/crafting-perfect-twitter-bio-profile/'
                target='_blank'
                className='link-footer extra-link'
              >
                Twitter bio link
              </Link>
              <Link
                href='https://blog.rebrandly.com/link-management'
                target='_blank'
                className='link-footer extra-link'
              >
                Link management
              </Link>
            </div>
            <div className='footer-col extra-links-col'>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/221598367-Edit-and-Brand-%20Instagram-Bio-Link'
                target='_blank'
                className='link-footer extra-link'
              >
                Instagram bio URL
              </Link>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/115006602668-How-to-Create-a-QR-Code-from-your-Short-Link'
                target='_blank'
                className='link-footer extra-link'
              >
                Create QR codes
              </Link>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/227131707-Bitly'
                target='_blank'
                className='link-footer extra-link'
              >
                Bitly alternative
              </Link>
              <Link
                href='https://blog.rebrandly.com/best-marketing-blogs'
                target='_blank'
                className='link-footer extra-link'
              >
                Marketing blogs
              </Link>
            </div>
            <div className='footer-col extra-links-col'>
              <Link
                href='https://rebrandly.com'
                target='_blank'
                className='link-footer extra-link'
              >
                URL shortener
              </Link>
              <Link
                href='https://blog.rebrandly.com/google-url-shortener/'
                target='_blank'
                className='link-footer extra-link'
              >
                Goo.gl
              </Link>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/227132207-bitly-enterprise'
                target='_blank'
                className='link-footer extra-link'
              >
                Bitly enterprise alternative
              </Link>
              <Link
                href='https://support.rebrandly.com/hc/en-us/articles/360001951033-migrate-short-urls'
                target='_blank'
                className='link-footer extra-link'
              >
                Migrate short URLs
              </Link>
            </div>
                                    </div>}
        </div>
      </div>
    )
  }
}

export default Footer
