export const isInViewport = function (elem) {
  if (typeof document === 'undefined') return null
  var distance = elem.getBoundingClientRect()
  return (
    distance.top >= 0 &&
      distance.left >= 0 &&
      distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      distance.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

// window.onload = function () {
//   var detector = document.querySelector('[data-detector]')
//   var changer = document.querySelector('[data-changer]')
//   if (document.body.clientWidth > 991) {
//     window.addEventListener('scroll', function (event) {
//       if (!isInViewport(detector)) {
//         changer.classList.remove('btn-secondary')
//         changer.classList.add('btn-primary-variant')
//       } else {
//         changer.classList.add('btn-secondary')
//         changer.classList.remove('btn-primary-variant')
//       }
//     }, false)
//   }
// }

export function setCookie (name, value, days) {
  if (typeof document === 'undefined') return null
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function getCookie (name) {
  if (typeof document === 'undefined') return null
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function eraseCookie (name) {
  if (typeof document === 'undefined') return null
  document.cookie = name + '=; Max-Age=-99999999;'
}
